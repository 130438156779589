import {
  Card,
  Container,
  Grid,
  NextUIProvider,
  Row,
  Text,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import set1 from "./set1.png";
import set2 from "./set2.png";

export const App = (props: any) => {
  const navigate = useNavigate();

  const handlePressedOffer = (e: any) => {
    switch (e.target.id) {
      case "1":
        navigate("/offer1");
        break;
      case "2":
        navigate("/offer2");
        break;
    }
  };

  return (
    <>
      <NextUIProvider>
        <div style={{ textAlign: "center" }}>
          <Text h1>Valentine's day gift set</Text>
        </div>
        <Container fluid>
          <Grid.Container style={{ marginTop: "2vh" }} gap={2} justify="center">
            <Grid xs>
              <Card
                isPressable
                id="1"
                onPress={handlePressedOffer}
                // css={{ minWidth: "330px", height: "220px" }}
              >
                <Card.Body css={{ p: 0 }}>
                  <Card.Image
                    src={set1}
                    objectFit="cover"
                    // width="60px"
                    // height="60px"
                    alt={""}
                  />
                </Card.Body>
                <Card.Footer css={{ justifyItems: "flex-start" }}>
                  {/* <Text b>
                    2 Palgong Tea's Bubble Tea + The Social Blend's Valentine's
                    Macaron Set
                  </Text> */}
                  <Row wrap="nowrap" justify="space-between" align="center">
                    <Text b>
                      2 Palgong Tea's Bubble Tea + The Social Blend's
                      Valentine's Macaron Set
                    </Text>
                  </Row>
                </Card.Footer>
                <Text
                  css={{
                    textAlign: "center",
                    fontWeight: "800",
                    fontSize: "1.5rem",
                    marginBottom: "15px",
                  }}
                >
                  $51.99
                </Text>
              </Card>
            </Grid>
            <Grid xs>
              <Card isPressable id="2" onPress={handlePressedOffer}>
                <Card.Body css={{ p: 0 }}>
                  <Card.Image src={set2} objectFit="cover" alt={""} />
                </Card.Body>
                <Card.Footer css={{ justifyItems: "flex-start" }}>
                  <Row wrap="nowrap" justify="space-between" align="center">
                    <Text b>2 Palgong Tea's Bubble Tea + 2 DEF-T Cans</Text>
                  </Row>
                </Card.Footer>
                <Text
                  css={{
                    textAlign: "center",
                    fontWeight: "800",
                    fontSize: "1.5rem",
                    marginBottom: "15px",
                  }}
                >
                  $42.99
                </Text>
              </Card>
            </Grid>
          </Grid.Container>
        </Container>
      </NextUIProvider>
    </>
  );
};
