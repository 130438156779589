import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";

import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider, createLightTheme } from "baseui";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PGTOffer1 } from "./PGTOffer1";
import { PGTOffer2 } from "./PGTOffer2";
import { Complete } from "./Complete";

const engine = new Styletron();

const theme = createLightTheme(
  {
    primaryA: "#dd3333",
    primary: "#dd3333",
  },
  []
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/offer1",
    element: <PGTOffer1 />,
  },
  {
    path: "/offer2",
    element: <PGTOffer2 />,
  },
  {
    path: "/complete",
    element: <Complete />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>
        <RouterProvider router={router} />
      </BaseProvider>
    </StyletronProvider>
  </React.StrictMode>
);
