import {
  Card,
  Container,
  Grid,
  NextUIProvider,
  Row,
  Text,
} from "@nextui-org/react";

export const Complete = (props: any) => {
  return (
    <>
      <NextUIProvider>
        <Container fluid>
          <div style={{ textAlign: "center" }}>
            <Text h1>Order is confirmed, please check your email.</Text>
          </div>
        </Container>
      </NextUIProvider>
    </>
  );
};
