import { FC, ReactNode } from "react";
import { useStyletron } from "baseui";
type CellWrapperProps = {
  children?: ReactNode;
};
export const CellWrapper: FC<CellWrapperProps> = ({ children }) => {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // padding: ".25rem",
      })}
    >
      {children}
    </div>
  );
};
