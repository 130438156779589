import { MaskedInput, Input, SIZE } from "baseui/input";
import { Cell } from "baseui/layout-grid";
import { useState } from "react";
import { CellWrapper } from "./components/CellWrapper";

export const PGTContact = (props: any) => {
  return (
    <>
      <Cell span={[12, 12, 3]}>
        <CellWrapper>
          <Input
            size={SIZE.large}
            value={props.firstName}
            onChange={(e) => props.setFirstName(e.target.value)}
            placeholder="First name"
            clearOnEscape
          />
        </CellWrapper>
      </Cell>
      <Cell span={[12, 12, 3]}>
        <CellWrapper>
          <Input
            size={SIZE.large}
            value={props.lastName}
            onChange={(e) => props.setLastName(e.target.value)}
            placeholder="Last name"
            clearOnEscape
          />
        </CellWrapper>
      </Cell>
      <Cell span={[12, 12, 3]}>
        <CellWrapper>
          <Input
            size={SIZE.large}
            value={props.email}
            onChange={(e) => props.setEmail(e.target.value)}
            placeholder="Email address"
            clearOnEscape
          />
        </CellWrapper>
      </Cell>
      <Cell span={[12, 12, 3]}>
        <CellWrapper>
          <MaskedInput
            value={props.phoneNumber}
            onChange={(e) => props.setPhoneNumber(e.target.value)}
            size={SIZE.large}
            placeholder="Phone number"
            mask="(999) 999-9999"
          />
        </CellWrapper>
      </Cell>
    </>
  );
};
