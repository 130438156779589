import { Select, SIZE, Value } from "baseui/select";
import { Cell } from "baseui/layout-grid";
import { useState } from "react";
import { CellWrapper } from "./components/CellWrapper";

export const PGTAppointment = (props: any) => {
  return (
    <>
      <Cell span={[12, 12, 4]}>
        <CellWrapper>
          <Select
            backspaceRemoves={false}
            size={SIZE.large}
            options={[
              { label: "Steeles", id: "1" },
              { label: "Distillery District", id: "2" },
              { label: "Hwy7", id: "5" },
              { label: "Yonge & Bloor", id: "6" },
            ]}
            value={props.storeLocation}
            searchable={false}
            placeholder="Select store location"
            onChange={(params) => props.setStoreLocation(params.value)}
          />
        </CellWrapper>
      </Cell>
      <Cell span={[12, 12, 4]}>
        <CellWrapper>
          <Select
            backspaceRemoves={false}
            size={SIZE.large}
            options={[
              { label: "February 12th", id: "1" },
              { label: "February 13th", id: "2" },
              { label: "February 14th", id: "3" },
            ]}
            value={props.pickupDate}
            searchable={false}
            placeholder="Select pickup date"
            onChange={(params) => props.setPickupDate(params.value)}
          />
        </CellWrapper>
      </Cell>
      <Cell span={[12, 12, 4]}>
        <CellWrapper>
          <Select
            backspaceRemoves={false}
            size={SIZE.large}
            options={[
              { label: "12 PM - 2 PM", id: "1" },
              { label: "2 PM - 4 PM", id: "2" },
              { label: "4 PM - 6 PM", id: "3" },
              { label: "6 PM - 8 PM", id: "4" },
            ]}
            value={props.pickupTime}
            searchable={false}
            placeholder="Select pickup time"
            onChange={(params) => props.setPickupTime(params.value)}
          />
        </CellWrapper>
      </Cell>
    </>
  );
};
