import { PGTAppointment } from "./PGTAppointment";
import { PGTPayment } from "./PGTPayment";
import { Button, SIZE, SHAPE } from "baseui/button";
import { PGTContact } from "./PGTContact";
import { Container, Text } from "@nextui-org/react";
import { NextUIProvider } from "@nextui-org/react";
import { Cell, Grid } from "baseui/layout-grid";
import { useState } from "react";
import { useStyletron } from "baseui";
import { Value } from "baseui/select";
import { valid } from "baseui/payment-card";
import { useNavigate } from "react-router-dom";

export const PGTOffer1 = (props: any) => {
  const [css] = useStyletron();

  const [isLoading, setIsLoading] = useState(false);

  const [storeLocation, setStoreLocation] = useState<Value>([]);
  const [pickupTime, setPickupTime] = useState<Value>([]);
  const [pickupDate, setPickupDate] = useState<Value>([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [number, setNumber] = useState("");
  const [expiration, setExpiration] = useState("");
  const [code, setCode] = useState("");
  const { card } = valid.number(number);

  const navigate = useNavigate();

  const handleSubmit = () => {
    setIsLoading(!isLoading);

    let headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Access-Control-Allow-Headers", "*");

    const requestOptions: RequestInit = {
      method: "POST",
      headers: headers,
      cache: "no-store",
      body: JSON.stringify({
        number,
        expiry_month: expiration.substring(0, 2),
        expiry_year: expiration.substring(3, 5),
        cvd: code,
        storeLocation: storeLocation,
        pickupTime: pickupTime,
        pickupDate: pickupDate,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        selectedOffer: "1",
      }),
    };
    fetch(
      "https://dashboard.pointsplenty.com/pgt/submitOrder.php",
      requestOptions
    )
      .then((r) => r.json())
      .then((r) => {
        if (r.status === "OK") {
          setIsLoading(!isLoading);
          navigate("/complete");
        } else {
          setIsLoading(!isLoading);
        }
      })
      .catch((err) => {
        setIsLoading(!isLoading);
        console.log(err);
      });
  };

  return (
    <>
      <NextUIProvider>
        <Container md>
          <Text h3>Pickup</Text>
        </Container>
      </NextUIProvider>
      <Grid gridGaps={[12]} gridGutters={[10]}>
        <PGTAppointment
          storeLocation={storeLocation}
          setStoreLocation={setStoreLocation}
          pickupTime={pickupTime}
          setPickupTime={setPickupTime}
          pickupDate={pickupDate}
          setPickupDate={setPickupDate}
        />
      </Grid>
      <NextUIProvider>
        <Container md>
          <Text h3 className={css({ marginTop: "15px" })}>
            Customer Billing Info
          </Text>
        </Container>
      </NextUIProvider>
      <Grid gridGaps={[12]} gridGutters={[10]}>
        <PGTContact
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      </Grid>
      <NextUIProvider>
        <Container md>
          <Text h3 className={css({ marginTop: "15px" })}>
            Payment
          </Text>
        </Container>
      </NextUIProvider>
      <Grid gridGaps={[12]} gridGutters={[10]}>
        <PGTPayment
          number={number}
          setNumber={setNumber}
          expiration={expiration}
          setExpiration={setExpiration}
          code={code}
          setCode={setCode}
          card={card}
        />
      </Grid>
      <Grid gridGaps={[12]} gridGutters={[10]}>
        <Cell span={[12, 12, 12]}>
          <Button
            size={SIZE.large}
            shape={SHAPE.pill}
            isLoading={isLoading}
            onClick={() => {
              handleSubmit();
            }}
            className={css({
              marginTop: "55px",
            })}
          >
            Place Order
          </Button>
          <div
            className={css({
              marginTop: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              marginBottom: "20px",
            })}
          >
            <div
              className={css({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                bottom: "1px",
              })}
            >
              <span
                className={css({
                  fontSize: "24px",
                })}
              >
                Secure payments provided by
              </span>
              <img
                className={css({
                  marginLeft: "4px",
                })}
                alt=""
                src="https://demo.na.bambora.com/static/images/logo-bambora.svg"
              />
            </div>
          </div>
        </Cell>
      </Grid>
    </>
  );
};
