import { PaymentCard, valid } from "baseui/payment-card";
import { MaskedInput, SIZE } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { useStyletron } from "baseui";
import { useState } from "react";
import { Cell } from "baseui/layout-grid";

function getFormOverrides(width: string) {
  return {
    ControlContainer: {
      style: {
        width,
        margin: "5px",
      },
    },
  };
}

export const PGTPayment = (props: any) => {
  const [css] = useStyletron();

  let codeLength;

  if (props.card && props.card.code && props.card.code.size) {
    codeLength = props.card.code.size;
  }

  return (
    <Cell span={[12, 12, 12]}>
      <div className={css({ display: "flex" })}>
        <FormControl
          overrides={getFormOverrides("350px")}
          caption="Card number"
        >
          <PaymentCard
            size={SIZE.large}
            value={props.number}
            onChange={(event) => props.setNumber(event.currentTarget.value)}
            placeholder="Card number"
          />
        </FormControl>
      </div>
      <div className={css({ display: "flex" })}>
        <FormControl
          overrides={getFormOverrides("100px")}
          caption="Expiration date"
        >
          <MaskedInput
            size={SIZE.large}
            value={props.expiration}
            onChange={(event) => props.setExpiration(event.currentTarget.value)}
            placeholder="MM/YY"
            mask="99/99"
          />
        </FormControl>
      </div>

      <div className={css({ display: "flex" })}>
        <FormControl overrides={getFormOverrides("90px")} caption="CVC">
          <MaskedInput
            size={SIZE.large}
            value={props.code}
            onChange={(event) => props.setCode(event.currentTarget.value)}
            placeholder="CVC"
            mask={codeLength ? "9".repeat(codeLength) : "999"}
          />
        </FormControl>
      </div>
    </Cell>
  );
};
